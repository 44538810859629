<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar :toolbarName="dynamicToolbarName" :openDetails="openDetails" />

    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formInternalUser">
        <v-toolbar flat v-if="canToggleUser">
          <v-switch
              v-model="payload.is_active"
              :label="getStatus(payload.is_active)"
              color="red"
              @change="toggleUser(payload.id)"
              :value="payload.is_active"
              hide-details
          ></v-switch>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome"/>
            <simple-text-field v-model="payload.name"
                              required
                              placeholder="Nome"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Email"/>
            <simple-text-field v-model="payload.email"
                              required
                              email
                              placeholder="Email"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Telefone"/>
            <simple-text-field v-model="payload.phone"
                              mask="phone"
                              placeholder="Telefone"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Função"/>
            <simple-select :items="authorities"
                          v-model="payload.authority_id"
                          placeholder="Selecione a Função"
                          itemText="name"
                          itemValue="id"
                          :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                          required
                          :rules="requiredRules"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6" v-if="!openDetails">
            <custom-label label="Status"/>
            <simple-select
              v-model="payload.is_active"
              :items="statusType"
              itemText="text"
              itemValue="value"
              class="mr-2"
              placeholder="Status"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6" v-if="this.openDetails">
            <span class="font-weight-bold">Senha</span> <br/>

            <primary-button
                label="Nova Senha"
                style="margin-top: 0.3rem;"
                type="button"
                @callAction="showConfirmResetPasswordDialog = true"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar v-if="!openDetails" v-model="search"
      @externalFiltersClear="clearFilters" :noSecondaryInput="false"
      :noTertiaryInput="false">

      <template v-slot:[`tertiary-input`]>
        <simple-select
          v-model="roleName"
          :items="roles"
          @input="filterByRole"
          itemText="name"
          itemValue="name"
          class="mr-2"
          placeholder="Função"
          height="0"
          :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
        />
      </template>

      <simple-select
        v-model="isActiveSearch"
        :items="statusType"
        @input="filterByStatus()"
        itemText="text"
        itemValue="value"
        class="mr-2"
        placeholder="Status"
        height="0"
        :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
      />
    </search-bar>

    <row-details v-if="openDetails">
      <v-row align="center">
        <v-col class="d-flex justify-start">
          <span class="font-weight-bold">Detalhes</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="!this.superAdminSelected && this.payload.id !== this.loggedUser.id"
          class="d-flex justify-end">
          <v-icon @click="edit()"> mdi-pencil </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <col-details v-if="payload.name != null" name="Nome" :item="payload.name"/>
        <col-details v-if="payload.authority != null" name="Função" :item="payload.authority.name"/>
        <col-details name="Status" :item="getStatus(this.payload.is_active)"/>
      </v-row>
    </row-details>

    <data-table :headers="headers"
                :fetching="fetching"
                :data="data"
                :search="search.text"
                :objectName="objectName"
                @loadAgain="loadAgain"
                v-else
    >
      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon
          v-if="checkDeleteItem(item)"
          class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-reset-password-dialog
      :payload="this.payload"
      :dialog.sync="showConfirmResetPasswordDialog"
      @closeDialog="closeConfirmResetPasswordDialog"
    />

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import CustomLabel from "@/components/texts/CustomLabel";
import SimpleTextField from "@/components/inputs/SimpleTextField";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import SearchBar from "@/components/layouts/SearchBar";
import Users from "@/domain/users/users";
import Authorities from "@/domain/authorities/authorities";
import ConfirmResetPasswordDialog from "@/components/dialogs/login/ConfirmResetPasswordDialog";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

import {getItem} from '@/services/local-storage.service';

export default {
  name: 'InternalUsers',
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    RowDetails,
    ColDetails,
    CustomLabel,
    SimpleTextField,
    SimpleSelect,
    SearchBar,
    ConfirmResetPasswordDialog,
    ConfirmDestroyDialog,
    PrimaryButton
  },
  data() {
    return {
      headers:
          [
            {text: "ID", align: "start", value: "id"},
            {text: "Nome", value: "name"},
            {
              text: "Função",
              value: "authority.name",
              filter: value => {
                if (!this.roleName) return true;
                return value === this.search.role;
              }
            },
            {
              text: "Status",
              value: "is_active",
              filter: value => {
                if (this.isActiveSearch === "") return true;
                return value === this.search.is_active;
              }
            },
            {text: "Detalhes", value: "actions", sortable: false},
          ],

      statusType: [
        {text: "Ativo", value: true},
        {text: "Inativo", value: false}
      ],

      objectName: 'usuários internos',
      toolbarName: 'Usuários Internos',
      formName: 'Adicionar Usuário',

      fetching: true,
      search: {},
      isActiveSearch: '',
      data: [],
      authorities: [],
      roles: [],
      roleName: '',

      userService: null,
      authorityService: null,

      payload: null,
      selected: null,
      canToggleUser: false,

      openDetails: false,
      formDialog: false,
      showConfirmResetPasswordDialog: false,
      showConfirmDestroyDialog: false,

      loggedUser: {},

      requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
    }
  },
  methods: {
    getUsers() {
      this.fetching = true;
      this.data     = [];
      this.roles    = [];

      this.userService.list().then((result) => {
        result.data.map((itm) => {
          if (itm.authority.level <= 1) {
            this.data.push(itm);
            this.roles.push(itm.authority.name);
          }
        });
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });

      this.fetching = false;
    },
    getAuthorities() {
      let loader = this.$loading.show();
      this.authorityService.getNameHighLevel().then((result) => {
        result.data.map((itm) => {
          this.authorities.push(itm);
        });
        loader.hide();
      })
      .catch(({response}) => {
        loader.hide();
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });
    },
    getStatus(status) {
      if (status === true) return "Ativo";

      return "Inativo";
    },
    toggleUser(id) {
      let loader = this.$loading.show();

      Users.toggle(id)
          .then(() => {
            loader.hide();
            this.$toasted.show("Status alterado com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
    },
    initialize() {
      this.userService      = Users;
      this.authorityService = Authorities;
      this.payload          = this.userService.newData();
      this.loggedUser       = JSON.parse(getItem('user'));

      this.getUsers();
      this.getAuthorities();
    },
    view(item) {
      this.payload = Object.assign({}, item);
      this.selected = Object.assign({}, item);
      this.openDetails = true;
    },
    closeDetails() {
      this.payload = {};
      this.selected = {};
      this.openDetails = false;
    },
    create() {
      this.payload = {};
      this.payload.is_active = true;
      this.selected = {};
      this.canToggleUser = false
      this.formDialog = true;
    },
    edit() {
      this.canToggleUser = true
      this.formDialog = true;
    },
    checkDeleteItem(item) {
      const level = item.authority.level === 0 ? true : false;

      if (!level && item.id !== this.loggedUser.id) return true;
      return false;
    },
    deleteItem(item) {
      this.payload = Object.assign({}, item);
      this.showConfirmDestroyDialog = true;
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeFormDialog() {
      this.selected = {};
      this.formDialog = false;
    },
    closeView() {
      this.openDetails = false;
      this.initialize();
    },
    save() {
      if (!this.$refs.formInternalUser.validate()) {
        return;
      }

      let loader = this.$loading.show();

      this.payload.is_active = this.payload.is_active === null
        ? false : this.payload.is_active;

      if (!this.openDetails) {
        this.userService.create(this.payload)
            .then(() => {
              loader.hide();
              this.closeFormDialog();
              this.initialize();
              this.$toasted.show("Usuário cadastrado com sucesso.", {
                type: "success",
              });
            })
            .catch(({ response }) => {
              loader.hide();
              const { error } = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      } else {
        this.userService.edit(this.payload)
            .then(() => {
              loader.hide();

              const authoritySelected = this.authorities.find(
                item => item.id === this.payload.authority_id
              );

              this.payload.authority.name = authoritySelected.name;
              this.closeFormDialog();
              this.$toasted.show("Usuário atualizado com sucesso.", {
                type: "success",
              });
            })
            .catch(({ response }) => {
              loader.hide();
              const { error } = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      }
    },
    async destroy() {
      const loader = this.$loading.show();

      await this.userService.delete(this.payload).then(() => {
        loader.hide();
        this.initialize();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Usuário removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Usuário não foi removido.", {
          type: 'error'
        });
      });
    },
    filterByRole() {
     this.search.role = this.roleName;
    },
    filterByStatus() {
      this.search.is_active = this.isActiveSearch;
    },
    clearFilters() {
      this.search         = {};
      this.isActiveSearch = "";
      this.roleName       = "";
    },
    closeConfirmResetPasswordDialog() {
      this.showConfirmResetPasswordDialog = false;
    },
    loadAgain() {
      this.initialize();
    }
  },
  computed: {
    superAdminSelected() {
      if (this.payload.authority.level === 0) {
        return true;
      }

      return false;
    },
    dynamicToolbarName() {
      if(this.openDetails) {
        return this.toolbarName + ' / Detalhes';
      }

      return this.toolbarName;
    },
    dynamicFormName() {
      if(this.openDetails) {
        return "Editar Usuário";
      }

      return this.formName;
    }
  },
  beforeMount() {
    this.initialize();
  }
}
</script>
